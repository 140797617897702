import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import { theme } from '../theme/theme';

interface StorageBannerProps {
  node: IStorageBanner;
}

const DefaultSettings = {
  entity_variant: {
    justify_content: 'space-between',
    theme: CMS_THEME.LIGHT,
  },
  entity_heading: {
    element: 'h2',
    style: 'typography_h4',
  },
  entity_buttons: {
    primary_button: 'btn',
  },
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StorageBanner: React.FC<StorageBannerProps> = ({ node: banner }) => {
  const settings = Object.assign(DefaultSettings, banner?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const primaryButtonVariation = settings.entity_buttons.primary_button;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const contentJustify = settings.entity_variant.justify_content;

  return (
    <Wrapper data-id={banner.drupal_id} className="py-3 px-0" fluid cmsTheme={cmsTheme}>
      <Container
        className={cx('d-flex align-items-center gap-5', `justify-content-${contentJustify}`)}
      >
        {banner?.title ? (
          <HeadingElement className={cx(headingStyle, 'mb-0')}>{banner.title}</HeadingElement>
        ) : null}
        {banner?.link?.map((link, index) => (
          <ButtonLink
            key={index} // Add a unique key for each link
            variant={primaryButtonVariation}
            to={link.url}
            cmsTheme={cmsTheme}
            {...link.options?.attributes}
          >
            {link.title ? link.title : null}
          </ButtonLink>
        ))}
      </Container>
    </Wrapper>
  );
};

export default StorageBanner;
